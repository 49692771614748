import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/agriculture/row-crop-tractors/6-family-row/',
    imageId: 'sixFamilyImage',
    name: '6-Family (145-230 HP)',
    alt: 'John Deere 6-Family Tractors',
  },
  {
    link: '/agriculture/row-crop-tractors/7-family/',
    imageId: 'sevenFamilyImage',
    name: '7-Family (210-310 HP)',
    alt: 'John Deere 7-Family Tractors',
  },
  {
    link: '/agriculture/row-crop-tractors/8-family/',
    imageId: 'eightFamilyImage',
    name: '8-Family (245-400 HP)',
    alt: 'John Deere 8-Family Tractors',
  },
  {
    link: '/agriculture/row-crop-tractors/9-family/',
    imageId: 'nineFamilyImage',
    name: '9-Family (370-620 HP)',
    alt: 'John Deere 9-Family Tractors',
  },
]

const RowCropTractorsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Row Crop Tractors | Hutson Inc</title>
        <meta
          name='description'
          content='Check out the latest and greatest 6-9 series John Deere row crop tractors available at Hutson Inc.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Row Crop Tractors',
                'item': 'https://www.hutsoninc.com/agriculture/row-crop-tractors/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Row Crop Tractors' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Row Crop Tractor' />

        <Videos
          type='Row Crop Tractor'
          id='SwLnWpzrZx0'
          list='PLUz5fj7f_mw_iIkCSDFGkqQPCE-gmxY4Z'
        />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query rowCropTractorsSubcategoryQuery($subcategory: String = "row-crop-tractors") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/row-crop-tractors-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    sixFamilyImage: file(relativePath: { eq: "agriculture/6-family.jpg" }) {
      ...FloatingGridImage
    }
    sevenFamilyImage: file(relativePath: { eq: "agriculture/7-family.jpg" }) {
      ...FloatingGridImage
    }
    eightFamilyImage: file(relativePath: { eq: "agriculture/8-family.jpg" }) {
      ...FloatingGridImage
    }
    nineFamilyImage: file(relativePath: { eq: "agriculture/9-family.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default RowCropTractorsPage
